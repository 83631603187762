export const FLORA_SHOPIFY = "https://shop.florasense.com";
export const BUY_FLORA = "https://shop.florasense.com/buy";
export const FLORA_POD =
  "https://florapreorder.com/products/flora-pod-%E2%84%A2-sensor-v2-2";
export const GET_ANDRIOD =
  "https://play.google.com/store/apps/details?id=com.florasense&referrer=utm_source%3Dweb%26utm_medium%3Dhbanner";
export const GET_IOS = "https://apps.apple.com/app/id1556133160";
export const GET_APP = "https://florazzbk6.app.link/3dmXlxvvQDb";
export const DOWNLOAD = "https://florazzbk6.app.link/3dmXlxvvQDb";
export const PRIVACY = `${FLORA_SHOPIFY}/privacy`;
export const TOS = `${FLORA_SHOPIFY}/tos`;
export const CONTACT_US = `https://shop.florasense.com/pages/hello`;

export const SOCIAL_LINKS = {
  FACEBOOK: "https://www.facebook.com/florasavesplants",
  INSTA: "https://www.instagram.com/florasavesplants/",
  TIKTOK: "https://www.tiktok.com/@florasavesplants?lang=en",
};
